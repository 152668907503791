.theme-exam {
  background: #fafcff;
}
.body-wrapbox {
  min-height: 100vh;
}

.student__onboarding__widget {
  padding: 0px 20px 20px 20px;
  display: flex;
  gap: 5%;
  justify-content: center;
}
.Rep_main_home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  height: 100vh;
}
.Rep_btn-cont {
  display: flex;
  justify-content: space-between;
}
.Rep_back-btn {
  display: inline-flex;
  padding: 14px 20px;
  align-items: center;
  gap: 8px;
  border-radius: 55px;
  border: 1px solid var(--Color-Dark-Grey-500, #263643);
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
  color: var(--Color-Dark-Grey-500, #263643);
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.12px;
  text-transform: uppercase;
}

.Rep_exam-title {
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 39px */
  margin: 1.6rem 0rem;
}
.Rep_sub-head {
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 2rem 0rem;
}
.Rep_score_card {
  position: relative;
  width: 45%;
  height: 180px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid var(--Secondary-Darkmode, #ffed9d);
  background: var(--Secondary-Light, #fff7d4);
  padding: 1rem;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.Rep_score_card2 {
  display: flex;
}
.Rep_score_cardinner {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 48%;
  height: 85px;
  padding: 0px 12px;
}
.Rep_score_cardinner img {
  width: 3rem;
}
.Rep_score_inner_title {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}
.Rep_score_inner_marks {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
}
.saparator_v {
  position: absolute;
  background-color: #d9dbe9;
  width: 2px;
  height: 100%;
  right: 50%;
  top: 0;
}
.saparator_h {
  position: absolute;
  background-color: #d9dbe9;
  width: 100%;
  height: 2px;
  top: 50%;
  right: 0;
}
.Rep_score_cardHead {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}
.Rep_score_cardDes {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
}
.Rep_score_cardButtom {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.Rep_score_cardLine {
  width: 100%;
}
.Rep_graph_card {
  width: 46%;
  height: 180px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.Rep_card-cont {
  display: flex;
  gap: 10px;
}
.emsATRep_card-cont {
  display: flex;
  gap: 10px;
  width: 95%;
}
.Rep_graph_cardHead {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Rep_graph_cont {
  display: flex;
  gap: 4rem;
}

.Rep_lable_cont {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.Rep_lable_contIn {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.Rep_lable_contIn img {
  margin-bottom: 1rem;
}
.Rep_Sub_cont {
  margin: 1rem 0rem;
  width: 96%;

  max-height: 238px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}

.cetrificate-cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 13px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #d9dbe9;
  background: #fff;
  margin-bottom: 1rem;
}
.mock-para {
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}
.Rep_Sub_contSubject {
  color: #4e4b66;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0%;
}
.Rep_Sub_contIn {
  display: flex;
  flex-wrap: wrap;
}
.Rep_Sub_cont-card {
  width: 50%;
}

.progressbar {
  width: 340px;
  height: 20px;
  border-radius: 20px;
  background: var(--Grayscale-Input-Background, #eff0f6);
}
.progress_filler {
  height: 20px;
  border-radius: 20px;
  background: var(--Secondary-Default, #fbd323);
  display: flex;
  justify-content: center;
  text-align: center;
}
.negitive_progress {
  color: var(--Error-Default, #b7295a);
  font-family: Montserrat;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  margin-top: 2px;
}
.Rep_progressor-cont {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.tab_subject {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  opacity: 0.5;
  border: none;
  background-color: transparent;
  margin-bottom: 1rem;
  height: 3rem;
}
.selected_tab {
  opacity: 1;
  border-bottom: 2px solid #3c8dcb;
  margin-right: 0.6rem;
}
.rep_select {
  display: flex;
  width: 336px;
  margin: 1rem 0rem;
  height: 45px;
  flex-shrink: 0;
  border-radius: 32px;
  padding: 0rem 1.3rem;
  background: #fff;
  border: none;
  box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.16);
  appearance: none;
  justify-content: space-between;
  align-items: center;
}

.rep_select_option {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1rem;
  position: absolute;
  z-index: 2;
  width: 191px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.05);
}
.rep_select_option h4 {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.rep_select_option h4:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}
.Rep_timer-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 326px;
  border-radius: 16px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  margin: 1.5rem 0;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.Rep_timer-cont img {
  width: 116px;
  height: 116px;
  flex-shrink: 0;
  margin-bottom: 1rem;
}
.Rep_timer-cont h1 {
  color: var(--Grayscale-Title-Active, #14142b);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 109.091% */
}
.Rep_timer-des {
  color: var(--Grayscale-Body, #4e4b66);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
.Rep_timer-des span {
  font-weight: 700;
}
.Rep_timer-time {
  color: var(--IL-primary-color, #3c8dcb);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 20.8px */
}
.Rep_score_card3 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.mock_imgweb {
  display: block;
}
.mock_imgmob {
  display: none;
}
/* Define animation for rotating the arrow */
@keyframes rotateUp {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-180deg);
  }
}

@keyframes rotateDown {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* Apply rotation animation when isOpen is true */
.rotate-up {
  animation: rotateUp 0.3s linear forwards;
}

/* Apply rotation animation when isOpen is false */
.rotate-down {
  animation: rotateDown 0.3s linear forwards;
}

/* Ensure the image doesn't change its position during rotation */
.arrow-img {
  width: 12px; /* Adjust the width as needed */
  height: auto;
}
.Rep_progress {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Rep_back-btn2 {
  display: none;
}
.Rep_main_cont {
  width: 989px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.circle-cont1 {
  display: block;
}
.circle-cont2 {
  display: none;
}
.Rep-text-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.Loader {
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}
@media only Screen and (min-width: 320px) and (max-width: 768px) {
  .Rep_btn-cont {
    align-items: center;
    width: 375px;
    height: 48px;
    flex-shrink: 0;
  }
  .Rep_back-btn {
    display: none;
  }
  .Rep_back-btn2 {
    display: block;
  }
  .Rep_exam-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .cetrificate-cont {
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
  .Rep_main_cont {
    width: 375px;
  }
  .Rep_attempt-btn1 {
    display: flex;
    color: var(--IL-primary-color, #3c8dcb);
    align-items: flex-end;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    background-color: transparent;
    text-wrap: nowrap;
  }
  .Rep_card-cont {
    flex-direction: column;
    gap: 1rem;
  }
  .Rep_score_card {
    width: 85%;
  }
  .Rep_graph_card {
    width: 85%;
    height: 162px;
  }
  .Rep_lable_title {
    font-size: 14px;
  }
  .Rep_lable_contIn img {
    margin-bottom: 0rem;
  }
  .Rep_Sub_cont-card {
    width: 100%;
  }
  .Rep_Sub_cont {
    height: 285px;
    width: 87%;
  }
  .Rep_graph_cont {
    margin-top: 1rem;
  }
  .Rep_lable_contIn {
    gap: 0.23rem;
  }
  .circle-cont1 {
    display: none;
  }
  .circle-cont2 {
    display: block;
  }
  .Rep_attempt-btn2 {
    align-self: start;
    width: 188px;
    font-size: 12px;
  }
  .Rep_score_card3 {
    gap: 2.3rem;
  }
  .Rep_back-btn2In {
    display: flex;
    gap: 1rem;
    color: #000;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .mock_imgweb {
    display: none;
  }
  .mock_imgmob {
    display: block;
    width: 5.5rem;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
  }
  .Rep_mock-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.25px;
  }
  .Rep_mock-Cont {
    width: 180px;
    margin-bottom: 0.6rem;
  }
  .Rep_mock-para {
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 127.273% */
  }
  .Rep-text-cont {
    width: 100%;
    gap: 0px;
    justify-content: space-between;
    position: relative;
  }
  .selected_tab {
    border-radius: 8px 8px 0px 0px;
    border-top: 0.6px solid var(--Grayscale-Line, #d9dbe9);
    border-right: 0.6px solid var(--Grayscale-Line, #d9dbe9);
    border-left: 0.6px solid var(--Grayscale-Line, #d9dbe9);
    background: var(--Grayscale-Background, #f7f7fc);
    padding: 1rem;

    border-bottom: 0px;
  }
  .tab_subject {
    font-size: 16px;
  }
  .visibleblock {
    display: none;
  }
}
@media only Screen and (min-width: 768px) and (max-width: 960px) {
  .Rep_main_cont {
    padding: 1.5rem 1rem;
  }
  .Rep_graph_cont {
    gap: 2rem;
  }
  .circle-cont1 {
    display: none;
  }
  .circle-cont2 {
    display: block;
  }
  .Rep_lable_contIn {
    gap: 0.23rem;
  }
  .Rep_lable_title {
    font-size: 14px;
  }
  .Rep_lable_cont {
    width: 60%;
  }
}

/*EMsatReports*/

.EMsatRep_score_card {
  width: 93%;
  border-radius: 10px;
  background: #f6f5ee;
  padding: 2rem 2rem;
  margin-top: 1rem;
}
.EMsatRep_score_card p {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  display: flex;
}
.EMsatRep_score_carditem {
  display: flex;
  gap: 5px;
}
.EMsatRep_score_card_dot {
  text-align: center;
  align-content: center;
  width: 1.8rem;
}
.EMsatRep_score_card_phigh {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  height: 10px;
}
.EMRep_Sub_cont {
  margin: 1rem 0rem;
  width: 96%;
  height: 258px;
  flex-shrink: 0;
  display: flex;
  gap: 1rem;

  /* card shadow */
}
.EMRep_Sub_CirclerCont {
  width: 30%;
  height: 238px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 2px;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.EMRep_Sub_TrackCont {
  position: relative;
  overflow: hidden;
  width: 100%;
  flex-shrink: 0;
  height: 8rem;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
}

.EMRep_Sub_ClgTirCont {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  height: 1rem;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;

  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 1.5rem 1rem;
}
.EMRep_Sub_ClgTirCont img {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 1.5rem;
}
.EMRep_Sub_ClgTirCont p {
  margin-top: 1rem;
}
.EMRep_Sub_contIn {
  width: 100%;
}

.EMRep_Sub_tripGraf {
  position: absolute;
  width: 93%;
  bottom: 2.2rem;
  left: 1.6rem;
  z-index: 1;
}
.EMRep_ChapterW_cont {
  margin: 1rem 0rem;
  width: 96%;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.EMRep_Sub_tripnuber0 {
  width: 19%;
  height: 100%;
  color: var(--Color-Dark-Grey-600, #1e2a36);
  text-align: end;
  align-content: end;
  font-family: Montserrat;
  font-size: 12.86px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 16.718px */
}
.EMRep_Sub_tripnuber300 {
  width: 80%;
  height: 100%;
  color: var(--Color-Dark-Grey-600, #1e2a36);
  text-align: end;
  align-content: end;
  font-family: Montserrat;
  font-size: 12.86px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.EMRepprogress_filler {
  height: 20px;
  border-radius: 20px;
  background: var(--Secondary-Default, #fbd323);
  overflow: visible;
}
.EMRepprogress_INfiller {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.EMRepprogress_INfiller img {
  height: 200px;
}
.EMRep_Sub_tripnuber {
  width: 100%;
  display: flex;
  height: 100%;
}
.EMRep_Sub_tripyou {
  width: 100%;
  overflow-x: hidden;
}
.EMRep_Sub_tripuyouImg {
  position: absolute;
  top: 1.5rem;
  transition: left 1s ease-in-out;
  display: flex;
  flex-direction: column;
}
.EMRep_Sub_tripYou {
  z-index: 1;
}
.EMRep_Sub_tripuyouline {
  width: 4px;
  position: absolute;
  top: 55px;
  left: 48%;
  z-index: 0;
}
.EMRep_Sub_tripCesr {
  position: absolute;
  top: 4rem;
  width: 18px;
}
.EMRep_Sub_tripCeserCont {
  position: relative;
}
.EMRep_Sub_tripuCesline {
  width: 2.5px;
  position: absolute;
  top: 75px;
  left: 8px;
  z-index: 0;
}
.EMRep_score {
  color: #79bc82;
  text-align: center;
  font-family: Montserrat;
  font-size: 14.289px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%; /* 18.575px */
  margin: 0;
}
.EMRep_cutoff {
  color: var(--Color-Dark-Grey-600, #1e2a36);
  text-align: center;
  font-family: Montserrat;
  font-size: 12.86px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 16.718px */
  margin-top: 3rem;
}
.EMRep_negtiveT {
  color: var(--Color-Dark-Grey-600, #1e2a36);
  text-align: center;
  font-family: Montserrat;
  font-size: 10.002px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 13.003px */
  width: 80px;
  position: absolute;
  top: 80px;
}
.EMRep_pai-chart-svg {
  margin-top: 1rem; /* Adjust this value as needed to move the SVG up */
}
.EMRep_chart-label {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.EMRep_chart-label span {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.EMRe_info {
  border: solid 1px #95aaba;
  padding: 0.5px;
  width: 22px;
  border-radius: 15px;
  color: #95aaba;
  align-content: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}
.EMRep_chart-labelCont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
}
.EMRep_chart-labelContPara {
  color: var(--grayscale-label-icons-grey, #6e7191);
  text-align: right;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.EMRep_chart-labelContPara span {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 4px;
}
.EMRep_tooltipp {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: calc(100% + 8px);
  right: -900%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
  animation: tooltipFadeDown 0.3s ease forwards;
  color: var(--Selection-Color-2, #274681);
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.2px;
  border: none;
}

.EMRep_tooltip {
  width: 420px;
  height: 112.501px;
  flex-shrink: 0;
  fill: #fff;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  display: flex;
  flex-direction: column;
  text-align: end;
  padding: 2rem;
}

.EMRep_tooltip img {
  width: 15rem;
  align-self: end;
  margin-bottom: 0.8rem;
}

.EMRep_tooltip .arrow-down {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff; /* Match tooltip background color */
}
.EMRep_graph_card {
  display: flex;
  justify-content: space-between;
  width: 45%;
  height: 214px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.EMRep_timeProgress_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 45%;
  height: 214px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.EMRep-column-timeprogress-textCont {
  display: flex;
}
.EMRep-timeprogress-dotcont {
  display: flex;
  margin-bottom: 0px;
  height: 25px;
  align-items: center;
}
.EMRep-column-timeprogress-textCont p {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 26px */
  margin-bottom: 0px;
}
.EMRep-column-progress-textCont h1 {
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.EMRep-column-progress-bar {
  width: 73px; /* Adjust the width as needed */
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 1.3rem;
  margin-left: auto;
  z-index: 1;
}

.EMRep-correct-section {
  background-color: #00ba88; /* Green for correct answers */
  transition: height 0.5s ease;
  z-index: 3;
  overflow-x: visible;
}

.EMRep-incorrect-section {
  background-color: #b7295a; /* Red for incorrect answers */
  transition: height 0.5s ease;
  z-index: 3;
  overflow-x: visible;
  display: flex;
  align-items: flex-end;
}
.EMRep-correctline {
  align-self: flex-end;
}
.EMRep-unAnsline {
  align-self: flex-start;
}
.EMRep-unanswered-section {
  transition: height 0.5s ease;
  border-radius: 4px 4px 0px 0px;
  background: var(--Grayscale-Line, #d9dbe9);
  z-index: 3;
  overflow-x: visible;
  display: flex;
  align-items: flex-end;
}
.EMRep-pointer_cont {
  color: var(--grayscale-label-icons-grey, #6e7191);
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 8px;
  margin-bottom: 0px;
}
.EMRep-pointer_Qus {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.EMRep-0 {
  border-top: solid 1px #a5aaaf;
  color: var(--Grayscale-Placeholder, #a0a3bd);
  text-align: end;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
}
.EMRep-100 {
  color: var(--Grayscale-Placeholder, #a0a3bd);
  text-align: end;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  position: absolute;
  top: 20px;
}
.EMRep-column-2Cont {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  gap: 10px;
}
.EMRep-column-2Cont1 {
  justify-content: space-between;
}
.EMRep-lineCont {
  position: absolute;
  z-index: 1;
  display: flex;
  right: 1px;
  gap: 1.4rem;
  width: 100%;
}

.EMRep-line1 {
  border: dashed 1px #a5aaaf;
  z-index: 0;
  height: 0px;
  width: 100%;
  border-radius: 2px;
  margin-left: 16px;
}
.EMRep-lineCont span {
  width: 10px;
  margin: 0px;
}
.EMRep-lineCont2 {
  padding-left: 2rem;
}
.EMRep-line2 {
  border: dashed 1px #a5aaaf;
  z-index: 0;
  height: 0px;
  width: 100%;
  border-radius: 2px;
  margin-left: 16px;
}
.EMRep-lineCont3 {
  padding-left: 4rem;
  align-self: flex-start;
}
.EMRep-line3 {
  border: dashed 1px #a5aaaf;
  z-index: 0;
  height: 0px;
  width: 100%;
  border-radius: 2px;
  margin-left: 16px;
}

.EMRep_progressbar {
  width: 100%;
  height: 20px;
  border-radius: 20px;
  background: var(--Grayscale-Input-Background, #eff0f6);
}
.EMRep_progress_filler {
  height: 14px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
}
.EMRep_progress_fillerCorret {
  background: #00ba88;
}
.EMRep_progress_filleriInCorret {
  background: #b7295a;
}
.EMRep_progress_fillerUnansr {
  background: #d9dbe9;
}
.EMRep_progres_container {
  width: 50%;
}
.EMRep_question_container {
  width: 50%;
}
.EMRep-column-progress-textCont2 {
  width: 50%;
  overflow: hidden;
}
.EMRep-pointer_cont1 {
  color: var(--grayscale-label-icons-grey, #6e7191);
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 8px;
  margin-bottom: 0px;
  height: 20px;
}
.EMRep-verticalLine {
  width: 1px;
  background: #d9dbe9;
  border-radius: 1px;
}
.EMRep-subcontainer {
  width: 50%;
  display: flex;
  padding: 1rem;
  align-items: center;
}
.EMRep-subcontainer1 {
  width: 75%;
}
.EMRep-subcontainer2 {
  width: 25%;
  border-bottom: 1px solid #a5aaaf;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 6rem;
}
.EMsat-barchart {
  background-color: #fbd323;
  width: 35px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.Emsat-Rep_Sub_cont {
  margin: 1rem 0rem;
  width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
}
.Emsat-Rep_Sub_contMob {
  display: none;
}
.EMRep-horydashedLine {
  border: 0.5px dashed #e7e8f1;
  width: 100%;
  margin-top: 0px;
}
.EMRep-value {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 2px;
}
.EMRep-subcontainer1 img {
  margin: 0px 2px;
}
.EMRep-AvgText {
  margin: 5px 0px;
}
.EMRep-TM-subcotainer {
  width: 50%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 30px;
}
.EMRep-TM-heading {
  width: 210px;
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.EMRep-TM-textCont {
  width: 100%;
}
.EMRep-TM-text {
  width: 220px;
  color: var(--grayscale-label-icons-grey, #6e7191);
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 8px;
  margin-bottom: 0px;
}
.EMRep-TM-INertextCont {
  margin: 12px 0rem;
}
.EMRep-TM-barcont {
  width: 100%;
  border-bottom: 1px solid #a5aaaf;
  display: flex;
  align-items: flex-end;
  height: 10rem;
  justify-content: center;
  gap: 20px;
}
.EMRep-TM-secCont {
  display: flex;
  width: 50%;
}

.EMsat-barprogress {
  border-radius: 6px 6px 0px 0px;
  width: 14px;
  height: 33.406px;
}
.subjectwise-cont {
  width: 100%;
  display: flex;
  gap: 10px;
  margin: 10px 0px;
  align-items: center;
}
.EMRep_SubW_cont {
  margin: 1rem 0rem;
  width: 96%;

  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.EMRep_SubW_contMob {
  display: none;
}

.subjectwise-name {
  width: 30%;
  color: var(--Color-Dark-Grey-500, #263643);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 0px;
}
.subjectwise-Score {
  width: 10%;
  color: #324452;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 10px;
  margin-bottom: 0px;
  align-items: center;
}
.subjectwise-Score span {
  color: var(--Color-Dark-Grey-600, #1e2a36);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 19.5px */
}
.subjectwise-Progressbar {
  width: 35%;
  height: 10px;
  flex-shrink: 0;
  display: flex;
  border-radius: 15px;
  margin: 10px;
}
.subjectwise-Minuts {
  width: 20%;
  color: #324452;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 10px;
  margin-bottom: 0px;
  align-items: center;
}
.subjectwise-Minuts span {
  color: var(--Color-Dark-Grey-600, #1e2a36);
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 19.5px */
}
.EM-SDR-cnt {
  width: 100%;
  margin-top: 1rem;
}
.EM-SDR-btn-cnt {
  display: flex;
  gap: 20px;
  height: 4rem;
  overflow-x: scroll;
}
.EM-SDR-btn-cnt::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.EMRep_SubW_Headercont {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--Color-Dark-Grey-200, #748494);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
}
.EMRep_SubW_HeaderCapter {
  width: 25%;
}
.EMRep_SubW_Headerimp {
  width: 10%;
}
.EMRep_SubW_Headertotal {
  width: 15%;
}
.EMRep_SubW_HeaderAcur {
  width: 15%;
}
.EMRep_SubW_Headermarks {
  width: 15%;
}
.EMRep_SubW_HeaderPerf {
  width: 15%;
}
.EMRep_SubW_cardCont {
  display: flex;
  width: 100%;
  gap: 10px;
}
.EMRep_SubW_BodyCapter {
  width: 25%;
  color: #263643;
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 161.538% */
}
.EMRep_SubW_Bodyimp {
  width: 10%;
  color: var(--Error-Dark, #a40038);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
}
.EMRep_SubW_Bodytotal {
  width: 15%;
  color: #263643;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
}
.EMRep_SubW_BodyAcur {
  width: 15%;
  color: var(--Color-Dark-Grey-500, #263643);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  display: flex;
  gap: 5px;
  align-items: center;
}
.EMRep_SubW_Bodymarks {
  width: 15%;
  color: #263643;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
}
.EMRep_SubW_BodyPerf {
  width: 15%;
  color: var(--Success-Dark, #00966d);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
}
.EMRep_SubW_BodyAcurBar {
  width: 60px;
  height: 3px;
  background-color: #e1f2ff;
  border-radius: 2px;
  display: flex;
  justify-content: left;
}
.EMRep_SubW_HeaderType {
  width: 35%;
}
.EMRep_SubW_BodyType {
  width: 35%;
  color: #263643;
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 161.538% */
}
.EMRep_Area_cont {
  width: 96%;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  padding: 1rem;

  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  gap: 10px;
}
.EMRep_Area_cont1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.EMRep_Area_cont1 h6 {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.EMRep_Area_content {
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
}
.EMRep_Area_content img {
  width: 30.181px;
  height: 30.126px;
}
.EMRep_Area_content p {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.EMRep_Area_content span {
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-feature-settings: "liga" off, "clig" off;

  /* Mobile/Body 2 - M */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.EMRep_graph_HeadMob h6 {
  width: 100%;
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "liga" off, "clig" off;

  /* Mobile/Small 2 - M */
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.EMRep_graph_cardMob {
  display: none;
}
.EMRep_timeProgress_cardMob {
  display: none;
}
.EMRep_progres_containermob {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0rem 1rem 0rem 4rem;
}
@media only screen and (min-width: 320px) and (max-width: 780px) {
  .EMRep_Sub_CirclerCont {
    width: 100%;
  }
  .EMRep_Sub_cont {
    flex-direction: column;
  }
  .EMRep_graph_card {
    width: 100%;
    display: none;
  }
  .EMRep_graph_cardMob {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--card-stroke, #e7e8f1);
    background: #fff;
    padding: 1rem;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  }
  .EMRep_timeProgress_card {
    width: 100%;
    display: none;
  }
  .EMRepprogress_INfiller img {
    height: 150px;
  }
  .EMRep_timeProgress_cardMob {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--card-stroke, #e7e8f1);
    background: #fff;
    padding: 1rem;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  }
  .EMsatRep_score_card p {
    color: var(--Grayscale-Body, #4e4b66);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.14px;
  }
  .Emsat-Rep_Sub_cont {
    flex-direction: column;
    display: none;
  }
  .EMRep-verticalLine {
    width: 100%;
    height: 1px;
  }

  .EMRep-TM-secCont {
    width: 100%;
  }
  .EMRep_tooltip {
    width: 300px;
  }
  .subjectwise-name img {
    display: none;
  }
  .EMRep_Sub_contIn {
  }
  .EMRep_Sub_contInmob {
    width: 100%;
  }
  .EMRep-pointer_cont1 {
    width: 50%;
    gap: 5px;
  }
  .EMRep_graph_PMobCont {
    display: flex;
    width: 100%;
    align-items: flex-start;
    align-content: flex-start;
    gap: 10px 16px;
    flex-wrap: wrap;
  }
  .EMRep_graph_PMobCont p {
    margin-bottom: 0px;
    color: var(--grayscale-label-icons-grey, #6e7191);
    text-align: right;
    font-feature-settings: "liga" off, "clig" off;

    /* Mobile/Small 3 - M */
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 127.273% */
    letter-spacing: 0.1px;
  }
  .EMRep_graph_PMobContIN {
    width: 100%;
    display: flex;
    gap: 10px;
  }
  .EMRep-column-progress-textCont2 {
    width: 100%;
  }
  .EMRep-100 {
    top: 2px;
  }
  .EMRep_progres_container {
    width: 100%;
  }
  .Emsat-Rep_Sub_contMob {
    margin: 1rem 0rem;
    width: 100%;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--card-stroke, #e7e8f1);
    background: #fff;
    padding: 1rem;
    /* card shadow */
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
  }
  .EMRep_progres_container h1 {
    color: var(--Grayscale-Title-Active, #14142b);
    font-feature-settings: "liga" off, "clig" off;

    /* Mobile/Small 2 - M */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
  .EMRep_progres_container p {
    color: var(--Grayscale-Title-Active, #14142b);
    font-feature-settings: "liga" off, "clig" off;

    /* Mobile/Button - SB */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.25px;
  }
  .EMRep-AvgText {
    color: var(--grayscale-label-icons-grey, #6e7191);
    text-align: right;
    font-feature-settings: "liga" off, "clig" off;

    /* Mobile/Small 3 - M */
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 127.273% */
    letter-spacing: 0.1px;
  }
  .EMRep-AvgText span {
    color: var(--Grayscale-Body, #4e4b66);
    font-feature-settings: "liga" off, "clig" off;

    /* Mobile/Button - SB */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.25px;
  }
  .EMRep-subcontainer1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  .EMRep-subcontainer {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
  .EMRep-TM-subcotainer {
    width: 100%;
  }
  .EMRep-TM-secContMOb {
    flex-direction: column;
    margin: 1rem;
  }
  .EMsat-barprogress {
    width: 40px;
  }
  .EMRep-TM-barcont {
    height: 6rem;
  }
  .subjectwise-contMob {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
  }
  .subjectwise-inerMob {
    display: flex;
    gap: 5px;
    width: 100%;
    margin-bottom: 10px;
  }
  .subjectwise-Score {
    font-size: 11px;
    gap: 5px;
    width: 20%;
  }
  .subjectwise-Score span {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
  .subjectwise-Progressbar {
    width: 40%;
  }
  .subjectwise-Minuts {
    width: 40%;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    align-items: flex-start;
    gap: 2px;
  }
  .subjectwise-Minuts span {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
  .subjectwise-vertLineMob {
    background: #d9dbe9;
    width: 100%;
    height: 1px;
    margin-bottom: 0.5rem;
  }
  .EMRep_SubW_cont {
    display: none;
  }
  .EMRep_SubW_contMob {
    margin: 1rem 0rem;
    width: 100%;
    display: block;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--card-stroke, #e7e8f1);
    background: #fff;
    padding: 1rem;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  }
  .EMRep_Area_cont {
    flex-direction: column;
    width: 100%;
    gap: 15px;
    margin: 10px 0px;
  }
  .EMRep_Area_cont1 {
    width: 100%;
  }
}

/*math reports*/

.MT-reports {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--Grayscale-Line, #d9dbe9);
  background: var(--Primary-BG, #fafcff);

  /* Drop Shadow Small */
  box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.06);
}
.Mt-reportHeader {
  width: 100%;
  background: var(--Primary-Light, #e1f2ff);
  display: flex;
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 212.5% */
  letter-spacing: 1px;
  padding-top: 1rem;
}
.Mt-SkillHeader {
  width: 25%;
  text-align: center;
}
.Mt-SubSkillHeader {
  width: 35%;
  text-align: center;
}
.Mt-ScoreHeader {
  width: 20%;
  text-align: center;
}
.Mt-StanineHeader {
  width: 20%;
  text-align: center;
}
.Mt-reportbody {
  display: flex;
  width: 100%;
  border-bottom: solid 1px #e2e2e2;
}
.Mt-Skillbody {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Mt-SubSkillcont {
  display: flex;
  flex-direction: column;
  width: 55%;
  text-align: center;
}
.Mt-subskillbody {
  width: 70%;
  color: #000;

  text-align: center;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  align-self: center;
}
.Mt-inSkillcont {
  height: 3rem;
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: solid 1px #e2e2e2;
}
.Mt-Scorebody {
  width: 30%;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-left: solid 1px #e2e2e2;
  padding-top: 1rem;
}
.Mt-Staninebody {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.MT-reportsGuide {
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 22px 16px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--Grayscale-Line, #d9dbe9);
  background: var(--Primary-BG, #fafcff);

  /* Drop Shadow Small */
  box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.06);
  margin: 1rem 0rem;
}
.MT-reportsGuide p {
  color: var(--Grayscale-Body, #4e4b66);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.MT-reportsGuide span {
  color: var(--Grayscale-Title-Active, #14142b);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.MT-reportsProfi {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Mt-Sub-SkillHeader {
  width: 35%;
  text-align: center;
}
.Mt-medHeader {
  width: 25%;
  text-align: center;
}
.Mt-Sub-Skillbody {
  border: solid 1px #e2e2e2;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Mt-medbody {
  border: solid 1px #e2e2e2;
  padding: 0.8rem;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Mt-medDotbody {
  width: 15px;
  height: 15px;
  border-radius: 10px;
}
.MT-reportsGuide img {
  width: 100%;
}
.Mathheader {
  width: 100%;
  position: relative;
}
@media only Screen and (min-width: 320px) and (max-width: 768px) {
  .Mt-Skillbody {
    font-size: 10px;
  }
  .Mt-subskillbody {
    font-size: 10px;
  }
  .MT-reportsGuide {
    width: 92%;
  }
  .MT-reportsGuide p {
    font-size: 12px;
  }
  .MT-reportsGuide span {
    font-size: 13px;
  }
  .Mt-Sub-Skillbody {
    font-size: 10px;
  }
}

.AdRp-main-cont {
  display: flex;
  width: 96%;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  margin-top: 1rem;

  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.AdRp-header-cont {
  display: flex;
  width: 100%;
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.Rep_maicont {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.AdRp-bodyitems {
  display: flex;
  width: 100%;
  height: 2.5rem;
}
.AdRp-bodyitems1 {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.AdRp-bodyitems2 {
  display: flex;
  width: 10%;
}
.AdRp-bodyitems3 {
  display: flex;
  width: 30%;
  gap: 0.8rem;
  align-items: center;
}
.AdRp-bodyitems4 {
  display: flex;
  width: 20%;
}

.AdRp-bodyitems1 h3 {
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px;
}
.AdRp-bodyitems1 p {
  color: #ff5353;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 15px */
  margin: 0px;
}
.AdRp-header-contp1 {
  width: 38%;
}
.AdRp-header-contp2 {
  width: 10%;
}
.AdRp-header-contp3 {
  width: 32%;
}
.AdRp-header-contp4 {
  width: 20%;
}
.AdRp-bodyitems3 img {
  width: 20px;
  height: 20px;
}
.AdRp-bodyitems3 p {
  color: var(--Grayscale-Title-Active, #14142b);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  width: 1rem;
}
.AdRp-bodyitems4 p {
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 25%;
}
.AdRp-bodyitems4 span {
  font-weight: 600 !important;
}
.AdRp-main-contMob {
  display: none;
}
.examlivemob {
  display: none;
}

.examliveweb {
  display: block;
}
.SCRPT-icnhdng {
  font-weight: 400;
}
@media only screen and (min-width: 320px) and (max-width: 786px) {
  .exam-titleCont {
    display: flex;
    justify-content: space-between;
  }
  .examliveweb {
    display: none;
  }

  .staus-display {
    padding: 5px;
    border-radius: 20px;
    border: 1px solid var(--Grayscale-Line, #d9dbe9);
    background: var(--Grayscale-Off-white, #fcfcfc);
    display: inline-flex;
    padding: 1px 5px;
    align-items: center;
    gap: 4px;
    color: red;
    font-weight: 400;
    font-size: 12px;
  }

  .examlivemob {
    display: block;
  }
  .AdRp-inMob-cont {
    border-top: 1px solid #e7e8f1;
    width: 100%;
  }
  .AdRp-bodyitems1 h4 {
    color: var(--grayscale-label-icons-grey, #6e7191);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
  }
  .AdRp-bodyitems1 h3 {
    line-height: 50%;
  }
  .AdRp-marksCont {
    display: flex;
    gap: 1rem;
    text-align: center;
    align-items: center;
  }
  .AdRp-marksCont p {
    color: var(--Grayscale-Title-Active, #14142b);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .AdRp-marksCont span {
    font-weight: 600;
  }
  .AdRp-bodyitems1 {
    width: 100%;
  }
  .AdRp-header-cont {
    gap: 7rem;
    margin-top: 1rem;
  }
  .AdRp-bodyitems {
    gap: 8rem;
  }
  .AdRp-main-cont {
    display: none;
  }
  .AdRp-main-contMob {
    display: flex;
    width: 90%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 10px;
    border: 1px solid var(--card-stroke, #e7e8f1);
    background: #fff;
    margin-top: 1rem;

    /* card shadow */
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  }
  .AdRp-mob-epandIcons {
    margin-left: auto;
  }
}

.perform_box {
  width: 95%;
  border-radius: 16px;
  border: 1px solid var(--secondary-darkmode, #ffed9d);
  background: var(--secondary-light, #fff7d4);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  margin-bottom: 20px;
}
.perform_webflex {
  display: flex;
  gap: 6px;
  align-items: center;
}
.perform_mobflex {
  display: flex;
  width: 315px;
  align-items: center;
  gap: 5px;
}
.no_mb {
}
.pl_header-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 6px;
}
.SCRPT-icnhdng {
  margin: 1rem 0rem;
}
@media only screen and (min-width: 320px) and (max-width: 780px) {
  .SCRPT-icnhdng {
    font-size: 12px;
    line-height: 20px;
  }
  .SCRPT-cunt {
    font-size: 18px;
  }
  .perform_box {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    padding: 30px;
    width: 76%;
  }
  .perform_webflex {
    gap: 20px;
  }
  .perform_mobflex {
    display: block;
    width: 300px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 576px) {
  .SCRPT-cunt {
    font-size: 30px;
  }
  .perform_webflex {
    gap: 20px;
  }
  .perform_box {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    padding: 30px;
  }
  .perform_mobflex {
    display: block;
    width: 300px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 780px) {
  .Rep_sub-head {
    font-size: 12px;
    align-content: start;
  }
}

.examName {
  position: absolute;
  top: 1px;
  left: 168px;
  height: 2rem;
  text-wrap: wrap;
  width: 16rem;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 20.834px;
  font-style: normal;
  font-weight: 700;
  line-height: 29.515px; /* 141.667% */
  letter-spacing: 0.868px;
}
.scoreExame {
  color: #0d5595;
}
.hotsExame {
  color: #eb8222;
}
.mathExame {
  color: #00918e;
}
.studentName {
  position: absolute;
  top: 114px;
  left: 180px;
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 13.889px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.834px; /* 150% */
}
.schoolid {
  position: absolute;
  top: 140px;
  left: 112px;
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 13.889px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.834px; /* 150% */
}
.date {
  position: absolute;
  bottom: 14px;
  left: 205px;
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 13.889px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.834px; /* 150% */
}
.grade {
  position: absolute;
  bottom: 15px;
  right: 40px;
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 13.889px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.834px; /* 150% */
}
@media only screen and (min-width: 320px) and (max-width: 780px) {
  .examName {
    left: 65px;
    top: -9px;
    font-size: 8px;
  }
  .grade {
    bottom: -1px;
    right: 14px;
    font-size: 8px;
  }

  .date {
    font-size: 6px;
    bottom: 1px;
    left: 80px;
  }
  .schoolid {
    top: 46px;
    left: 43px;
    font-size: 6px;
  }
  .studentName {
    top: 36px;
    left: 71px;
    font-size: 6px;
  }
}
